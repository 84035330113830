import StockInterface from "./interfaces/StockInterface"

class Stock {
    XS : number
    S : number
    M : number
    L : number
    XL : number

    constructor(stock ? : StockInterface) {
        this.XS = stock?.XS  ?? 0
        this.S = stock?.S ?? 0
        this.M = stock?.M ?? 0
        this.L = stock?.L ?? 0
        this.XL = stock?.XL ?? 0
    }
}

export default Stock
