import * as React from 'react';
import { useState, useEffect } from 'react';
import target from '../../objects/types/target';
import "./SelectTargetPerson.css"
interface SelectTargetPersonProps {
    value : Array<target>
    onChange : (rpme : Array<target>) => any
}
 
const SelectTargetPerson: React.FunctionComponent<SelectTargetPersonProps> = ({value=[], onChange}) => {
    const targets : Array<target> = [ "H", "F", 'E']
    const targetsString  = {
        "H" : "Homme",
        "F" : "Femme",
        "E" : "Enfant"
    }

    const onClickItem = (target : target) => {
        let newValue = [...value]
        if(value.includes(target)) {
           newValue = newValue.filter(v => v !== target)
        } else {
            newValue.push(target)
        }
        onChange(newValue)
    }
    return ( 
        <div className="container-select-target-person">
            {targets.map(target =>
                <div onClick={() => onClickItem(target)} className={`label ${value.includes(target) ? "bg-dark" : "bg-light"}`}>{targetsString[target]}</div>
            )}
        </div>
     );
}
 
export default SelectTargetPerson;
