import './App.css'
import Products from './pages/App/Products/Products';
import { HashRouter, Switch, Route, RouteComponentProps } from "react-router-dom";
import Categories from './pages/App/Categories/Categories';
import Collections from './pages/App/Collections/Collections';
import Orders from './pages/App/Orders/Orders';
import { useReducer, useEffect } from 'react';
import DataReducer from './hooks/reducers/DataReducer';
import DataContext from './hooks/contexts/DataContext';
import Customers from './pages/App/Customers/Customers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Users from './pages/App/Users/Users';
import Login from './pages/App/Login/Login';
import Invoices from './pages/App/Invoices/Invoices';
import Gifts from './pages/App/Gifts/Gifts';
import NfcTags from './pages/App/NfcTags/NfcTags';
import Settings from './pages/App/Settings/Settings';
import LaunchVisuals from './pages/App/LaunchVisuals/LaunchVisuals';
import Statistics from './pages/App/Statistics/Statistics';
import Notifications from './pages/App/Notifications/Notifications';
import useAPI from './hooks/useAPI';
import User from './objects/User';


const App = () => {

    const [data, dispatch] = useReducer(DataReducer, {
        categories: [],
        collections: [],
        products: [],
        isCall: {
            categories: false,
            collections: false,
            products: false
        }
    })


    return (
        <>
        <DataContext.Provider value={{data, dispatch}}>
            <HashRouter>
                <Switch>
                    <Route path="/products" component={Products}/>
                    <Route path="/gifts" component={Gifts}/>
                    <Route path="/categories" component={Categories}/>
                    <Route path="/category/:id" render={(props : RouteComponentProps) => <Products {...props} from="category" /> } />
                    <Route path="/collections" component={Collections}/>
                    <Route path="/collection/:id"  render={(props : RouteComponentProps) => <Products {...props} from="collection" /> }/>
                    <Route path="/orders/:id" render={(props : RouteComponentProps) => <Orders {...props} /> } />
                    <Route path="/orders" component={Orders}/>
                    <Route path="/settings" component={Settings}/>
                    <Route path="/nfc" component={NfcTags}/>
                    <Route path="/launch_visual" component={LaunchVisuals}/>
                    <Route path="/statistics" component={Statistics}/>
                    <Route path="/notifications" component={Notifications}/>
                    <Route path="/launch_visual" component={LaunchVisuals}/>
                    <Route path="/invoices" component={Invoices}/>
                    <Route path="/customers" component={Customers}/>
                    <Route path="/users" component={Users}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/" component={Orders}/>
                </Switch>
            </HashRouter>
        </DataContext.Provider>
        <ToastContainer position="bottom-right" />
        </>
    );
}

export default App;
