import File from './File';
import LaunchVisualInterface from './interfaces/LaunchVisualInterface';

class LaunchVisual {
    id ? : number
    file ? : File
    beginDate ? : string
    endDate ? : string
    isCurrent  ? : boolean
    constructor(launchVisual ? : LaunchVisualInterface) {
        this.id = launchVisual?.id
        this.file = new File(launchVisual?.file)
        this.beginDate = launchVisual?.beginDate
        this.endDate = launchVisual?.endDate
        this.isCurrent = launchVisual?.isCurrent
        console.log(this)
    }
}

export default LaunchVisual
