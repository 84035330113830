  
import axios from "axios";
import URL_API from "../constants/URL_API";
import { toast } from 'react-toastify';

const instance =  axios.create({
    baseURL: URL_API,
});

instance.interceptors.request.use(async request => {
    let token = await localStorage.getItem("token")
    if (token) {
        request.headers.common['Authorization'] = "Bearer " + token;
    }

    return request;
});

instance.interceptors.response.use(response => response, error => {
        const status = error.response.status
        const message = error.response.data.message

        if (status === 401 && message.toLowerCase().includes("jwt")) {
            console.error("Session expiré")
        }

        return Promise.reject(error);
});

export default instance;
