import role from './types/role';
import UserInterface from './interfaces/UserInterface';
import ColumnInterface from './interfaces/ColumnInterface';
import FormInterface from './interfaces/FormInterface';


class User {
    readonly id : number;
    email ? : string
    roles ? : Array<role>
    phone ? : string
    firstName ? : string
    lastName ? : string
    isActive ? : boolean

    get rolesString() {

        let roles = []
        if(this.roles?.includes("ROLE_TESTER")) roles.push("Testeur")
        if(this.roles?.includes("ROLE_ADMIN")) roles.push("Admin")
        if(this.roles?.includes("ROLE_SUPER_ADMIN")) roles.push("Super Admin")
        let rolesString = ""
        roles.forEach(r => {
            if(rolesString === "") rolesString = r
            else rolesString+= `, ${r}`
        })
        return rolesString
    }


    constructor(user : UserInterface){
        this.id = user.id
        this.email = user.email
        this.roles = user.roles
        this.phone = user.phone
        this.firstName = user.firstName
        this.lastName = user.lastName
        this.isActive = user.isActive
    }


    static get FORM_ADD() : Array<FormInterface> {
        return [{
            value : "phone",
            wording : "Téléphone",
            type : "text"
        }, {
            value : "firstName",
            wording : "Prénom",
            type : "text"
        }, {
            value : "lastName",
            wording : "Nom",
            type : "text"
        }, {
            value : "roles",
            wording : "Roles",
            type : "select_role"
        }]
    }

    static get FORM_EDIT() : Array<FormInterface> {
        return [{
            value : "phone",
            wording : "Téléphone",
            type : "text"
        }, {
            value : "firstName",
            wording : "Prénom",
            type : "text"
        }, {
            value : "lastName",
            wording : "Nom",
            type : "text"
        }, {
            value : "roles",
            wording : "Roles",
            type : "select_role"
        }, {
            value : "isActive",
            wording : "Actif",
            type : 'boolean'
        }]
    }

    static get COLUMNS() : Array<ColumnInterface>{
        return [{
            name : "Id",
            value : "id"
        },{
            name : "Téléphone",
            value : "phone"
        },{
            name : "Prénom",
            value : "firstName"
        }, {
            name : "Nom",
            value : "lastName"
        },{
            name : "Roles",
            value : "rolesString"
        }]
    }

    get BODY() {
        return {
            firstName : this.firstName,
            lastName : this.lastName,
            phone : this.phone,
            roles : this.roles,
            isActive : this.isActive
        }
    }
}

export default User
