import Order from './Order';
import InvoiceInterface from './interfaces/InvoiceInterface';
import ColumnInterface from './interfaces/ColumnInterface';
import URL_API from '../constants/URL_API';
import FilterInterface from './interfaces/FilterInterface';

class Invoice {
    id?: number
    custom_id?: string
    ordeer?: Order
    nbPoints?: number
    totalPrice?: number

    get firstNameCustomer() {
        return this.ordeer?.firstNameCustomer
    }

    get lastNameCustomer() {
        return this.ordeer?.lastNameCustomer
    }

    get emailCustomer() {
        return this.ordeer?.customer?.email
    }

    get idOrder() {
        return this.ordeer?.custom_id
    }


    get totalPriceString() {
        return `${this.totalPrice} €`
    }

    get filePath() {
        return `${URL_API}/api/invoice/pdf/${this.id}`
    }

    constructor(invoice ?: InvoiceInterface) {
        this.id = invoice?.id
        this.custom_id = invoice?.custom_id
        this.ordeer = (invoice?.ordeer) && new Order(invoice.ordeer)
        this.nbPoints = invoice?.nbPoints
        this.totalPrice = invoice?.totalPrice
    }

    static get COLUMNS(): Array<ColumnInterface> {
        return [{
            name: "N° de facture",
            value: "custom_id"
        }, {
            name: "Nom",
            value: "lastNameCustomer"
        }, {
            name: "Prénom",
            value: "firstNameCustomer"
        }, {
            name: "Adresse email",
            value: "emailCustomer"
        },
        {
            name: "Nb de Point",
            value: "nbPoints"
        }, {
            name: "Prix Total",
            value: "totalPriceString"
        }, {
            name: "Id Commande",
            value: "idOrder"
        }, {
            name : "Fichier",
            value : "filePath",
            type : "file"
        }]
    }

    public static get FILTERS_FOR_INVOICES(): Array<FilterInterface> {
        return [{
            wording: "N° de facture",
            value: "custom_id",
            type: "text"
        }, {
            wording: "Nom",
            value: "lastNameCustomer",
            type: "text"
        }, {
            wording: "Prénom",
            value: "firstNameCustomer",
            type: "text"
        }, {
            wording: "Adresse Mail",
            value: "emailCustomer",
            type: "text"
        }, {
            wording: "Nombre de points",
            value: "nbPoints",
            type: "number"
        }, {
            wording: "Prix Total",
            value: "totalPrice",
            type: "number"
        }, {
            wording: "Id Commande",
            value: "idOrder",
            type: "text"
        }, ]
    }
}

export default Invoice
