import SettingInterface from './interfaces/SettingInterface';

class Setting {
    id : number
    name : string
    type : "price" | "boolean"
    value : any

    constructor(setting : SettingInterface) {
        this.id = setting.id
        this.name = setting.name
        this.type = setting.type
        this.value = (setting.type === "price") ? setting.price : (setting.type === "boolean") ? setting.boolean : null
    }
}

export default Setting
