import * as React from 'react';
import MenuBurger from '../MenuBurger/MenuBurger';
import Navbar from '../Navbar/Navbar';
import "./Page.css";
import DataContext from '../../hooks/contexts/DataContext';
import JwtDecode from 'jwt-decode'
import { toast } from 'react-toastify';

const Page: React.FunctionComponent<PageProps> = ({children, history, name =""}) => {

    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = React.useState(false)
    
    const {data , dispatch} = React.useContext(DataContext)
    React.useEffect(() => {

        const getToken = async () => {
            let token = await localStorage.getItem("token")
            if(!data.token && !token) {
                history.push("/login")
            } else if(token) {
                console.log(JwtDecode<any>(token))
                const { exp : expiration , roles } = JwtDecode<any>(token)
                if (expiration * 1000 > new Date().getTime() && (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_SUPER_ADMIN") || roles.includes("ROLE_TESTER") )) {
                  dispatch({ reducerPath : "token", newData: token })
                  dispatch({ reducerPath : "roles", newData: roles })
                } else{
                    if (!(expiration * 1000 > new Date().getTime())) toast.error("Session Expiré")
                    else toast.error("Accès non autorisé")
                    localStorage.setItem("token", "")
                    dispatch({ reducerPath : "token", newData: "" })
                    dispatch({ reducerPath : "roles", newData: [] })
                }
            }
        }

        getToken()
    },[data.token])

    return ( 
        <>
            <Navbar namePage={name} onClickBurgerMenu={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)} history={history} />
            <div className="container">
                <MenuBurger  history={history} open={isBurgerMenuOpen} />
                <div className="page-container bg-light">
                    {children}
                </div>
            </div>
        </>
     );
}
 
export default Page;

export interface PageProps {
    children : JSX.Element
    history : any
    name ? : string
}
 