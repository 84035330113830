import * as React from 'react';
import { useContext } from 'react';
import ListContext from '../../hooks/contexts/ListContext';
import "./ListCards.css"


export interface ListCardsProps {
    data : Array<any>
    card : (item : any) => JSX.Element
    btnAddComponent ? : JSX.Element
    hasBtnAdd ? : boolean
    onClickBtnAdd ? : () => any
}


const ListCards: React.FunctionComponent<ListCardsProps> = ({data=[], card, btnAddComponent=<CardAdd />, hasBtnAdd=true, onClickBtnAdd=() => {}}) => {


    return ( 
        <ListContext.Provider value={{
            onClickBtnAdd
        }}>
        <div className="list-cards">
            {data.map(item =>
                <div key={item.id} className="container-card">
                    {console.log(item.id)}
                    {card(item)}
                </div>
            )}
            <div className="container-card">
                {btnAddComponent}
            </div>

        </div>
        </ListContext.Provider>
     );
}

export const CardAdd : React.FunctionComponent = () => {
    const {onClickBtnAdd} = useContext(ListContext)
    return (
        <div onClick={onClickBtnAdd} style={{width:"100%", height:"100%", textAlign:"center", alignItems:"center", cursor:"pointer"}} className="bg-white">
                <div style={{paddingTop:"70px", fontSize:"90px"}}>+</div>
        </div>)
}
 
export default ListCards;
