import * as React from 'react';
import "./MenuBurger.css";
import useData from '../../hooks/useData';
export interface MenuBurgerProps {
    open : boolean
    history : any
}
 
const MenuBurger: React.FunctionComponent<MenuBurgerProps> = ({open = true, history}) => {

    const [roles] = useData("roles")

    const onClick = (url : string)=>  {
        if(history.location.pathname.includes(url)) {
            window.location.reload()
        } else history.push(url)
    }
    return ( 
        <div className={(open ? "burger-menu" : "burger-menu-hide") + " bg-white"}>
            <NavItem onClick={() =>onClick("/orders")} title="Commandes"/>
            {roles?.includes("ROLE_SUPER_ADMIN") && <NavItem onClick={() => onClick("/users")} title="Gestion des Utilisateurs"/>}
            <NavItem onClick={() => onClick("/products")} title="Produits"/>
            <NavItem onClick={() => onClick("/categories")} title="Catégories"/>
            <NavItem onClick={() => onClick("/collections")} title="Collections"/>
            <NavItem onClick={() => onClick("/nfc")} title="Gestion des Tag NFC"/>
            <NavItem onClick={() => onClick("/gifts")} title="Chèques Cadeau"/>
            <NavItem onClick={() => onClick("/customers")} title="Clients"/>
            <NavItem onClick={() => onClick("/invoices")} title="Factures"/>
            <NavItem onClick={() => onClick("/notifications")} title="Notifications"/>
            <NavItem onClick={() => onClick("/launch_visual")} title="Visuel de Lancement"/>
            <NavItem onClick={() => onClick("/statistics")} title="Statistiques"/>
            <NavItem onClick={() => onClick("/settings")} title="Paramètres"/>
        </div>
     );
}

const NavItem = ({title = "", onClick = () => {}}) => {
    return(
        <div className="nav-link" onClick={onClick}>
            <label className="nav-link-title">{title}</label>
        </div>
    )
}
export default MenuBurger;
