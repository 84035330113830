import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyDXdLmC-7v2sRG6t5kSZ9xbEAJeInd9FYg",
    authDomain: "by-ciavatti.firebaseapp.com",
    projectId: "by-ciavatti",
    storageBucket: "by-ciavatti.appspot.com",
    messagingSenderId: "862939013635",
    appId: "1:862939013635:web:cafc830e703ede1255a436",
    measurementId: "G-P8H8LC8M99"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase
