class List {

    public static addItem(list: Array<any>, newItem : any) {
        let oldList : Array<any> = [...list]
        list.push(newItem)
        return [list, oldList]
    }

    public static deleteItem(list: Array<any>, id : number) {
        let oldList : Array<any> = [...list]
        list = list.filter(item => item.id !== id)
        return [list, oldList]
    }

    public static updateItem(list: Array<any>, updateItem : any, ) {
        let oldList : Array<any> = [...list]
        let dataUpdate : Array<any> = []
        list.forEach(i => {
            if(i.id !== updateItem.id)
                dataUpdate.push(i)
            else
                dataUpdate.push(updateItem)
        })
        list = dataUpdate
        return [list, oldList]
    }
}

export default List
