import * as React from 'react';
import "./UploadFile.css"
import { usePostFile, usePutFile, useDeleteFile } from '../../hooks/useAPI';
import { useState } from 'react';
import File from '../../objects/File';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

export interface UploadFileProps {
    size ? : number
    width ? : number
    height ? : number
    fileUploaded ? : File
    onUploadedFile : (file ? : File) => any
    wording : string
    id ? :string
    canBeDeleted ? : boolean
}
 
const UploadFile: React.FunctionComponent<UploadFileProps> = ({size=200, wording, onUploadedFile, fileUploaded, id, width, height, canBeDeleted=true}) => {

    const [uploadedFile, setUploadedFile] = useState<File | undefined>(fileUploaded)

    const [uploadFile, responseUploadFile] = usePostFile()
    React.useEffect(() => {
        const {onSuccess, data, onFailure} = responseUploadFile
        if(onSuccess) {
            setUploadedFile(data)
            onUploadedFile(data)
            toast.success("Succès de l'upload")
        }
        if(onFailure) {
            toast.error("Une erreur est survenue lors de l'upload")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseUploadFile] )

    const [changeFile, responseChangeFile] = usePutFile()
    React.useEffect(() => {
        const {onSuccess, data, onFailure} = responseChangeFile
        if(onSuccess) {
            setUploadedFile(data)
            onUploadedFile(data)
            toast.success("Succès de l'upload")
        }
        if(onFailure) {
            toast.error("Une erreur est survenue lors de l'upload")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseChangeFile] )

    const [deleteFile, responseDeleteFile] = useDeleteFile()
    useEffect(() => {
        const {onSuccess} = responseDeleteFile
        if(onSuccess) {
            setUploadedFile(new File({filepath : "", id : fileUploaded?.id, format : fileUploaded?.format}))
            onUploadedFile(new File({filepath : "", id : fileUploaded?.id, format : fileUploaded?.format}))
        }
    }, [responseDeleteFile]);

    useEffect(() => {
        setUploadedFile(fileUploaded)
    },[fileUploaded])

    const fileSelectedEvent = async (e : any) => {
        const {target} = e
        console.log(target.file)
        let file = new FormData();
        file.append("file", target.files[0]);
        if(!uploadedFile) uploadFile(file)
        if(uploadedFile) changeFile(file, uploadedFile?.id ?? 0)
    }

    return ( 
        <div className="container-upload-file">
            <div className="container-input-file">
                <label>{wording}</label>
                <input id={id ?? "upload-btn"} className="input" type="file" onChange={fileSelectedEvent} style={{display:"none"}}/>
            </div>
            <div className="img-upload" style={{width : width ?? size, height : height ?? size}} >
                <img alt="" src={uploadedFile?.url_img} style={{width:"100%", height:"100%"}}/>
                <label htmlFor={id ?? "upload-btn"} style={{width:"100%", height:"100%", position: "absolute",  left:0, cursor:"pointer"}}>
                    
                </label>
                {(fileUploaded?.filepath && canBeDeleted) && <div onClick={() => deleteFile(fileUploaded?.id ?? 0)} className="btn-delete bg-danger">                
                    <FontAwesomeIcon icon={faTrash}  />
                </div>}
            </div>
        </div>
     );
}
 
export default UploadFile;
