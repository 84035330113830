import * as React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../../components/Page/Page';

interface StatisticsProps extends RouteComponentProps<any>{
    
}
 
const Statistics: React.FunctionComponent<StatisticsProps> = ({history}) => {
    return ( 
        <Page history={history} name="Statistiques">
            <div className="statistiques-container">
                Stat
            </div>
        </Page>
     );
}
 
export default Statistics;
