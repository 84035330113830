import File from "./File";
import CategoryInterface from "./interfaces/CategoryInterface"
import FormInterface from "./interfaces/FormInterface";
import Product from "./Product";
import Trad from './Trad';
import FileInterface from './interfaces/FileInterface';

class Category {
    readonly id : number
    name : Trad
    visual ? : File
    products ? : Array<Product>

    constructor(category : CategoryInterface) {
        this.id = category.id ?? 0
        this.name = category.name ? new Trad(category.name) : new Trad({id : 0, fr:"", en:""})
        if(category.visual) this.visual = new File(category.visual)
        let products : Array<Product> = []
        category.products?.forEach(product => {
            products.push(new Product(product))
        })
        this.products = products
    }

    public set nameFR(name_fr : string) {
        this.name.fr = name_fr
    }

    public get nameFR() {
        return this.name.fr
    }

    public set nameEN(name_en : string) {
        this.name.en = name_en
    }

    public get nameEN() {
        return this.name.en ?? ""
    }

    public set visualFile(file : FileInterface) {
        this.visual = new File(file)
    }

    public get visualFile() : any {
        return this.visual ?? null
    }

    public get BODY() : Object {
        return {
            name : {
                fr : this.nameFR,
                en : this.nameEN
            },
            visual_id : this.visual?.id,
        }
    }

    public static get FORM_ADD() : Array<FormInterface> {
        return [{
            wording : "Nom de la catégorie FR :",
            value : "nameFR",
            type : "text"
        }, {
            wording : "Nom de la catégorie EN :",
            value : "nameEN",
            type : "text"
        }, {
            wording : "Visuel :",
            value : "visualFile",
            type : "file"
        }]
    }

    public static get FORM_EDIT() : Array<FormInterface> {
        return [{
            wording : "Nom de la catégorie FR :",
            value : "nameFR",
            type : "text"
        }, {
            wording : "Nom de la catégorie EN :",
            value : "nameEN",
            type : "text"
        }, {
            wording : "Visuel :",
            value : "visualFile",
            type : "file"
        }]
    }

    public static ERROR(message : string) : string {
        if(message === "CATEGORY_CANT_BE_DELETE") message = "La catégorie ne peut pas être supprimer tant qu'il y a encore des produits dans celle ci"
        return message
    }
}

export default Category
