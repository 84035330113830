import * as React from "react";
import request from "../objects/types/request";
import RequestInterface from "../objects/interfaces/RequestInterface";
import ResponseInterface from "../objects/interfaces/ResponseInterface";
import REQUESTS from "../constants/REQUESTS";
import method from "../objects/types/method";
import ServiceInterface from "../objects/interfaces/ServiceInterface";
import { postFile, putFile, setRequestAPI} from "../services/index";
import FileInterface from "../objects/interfaces/FileInterface";
import File from "../objects/File";
import DataContext from "./contexts/DataContext";
import List from "../objects/List";

const useAPI = (name? : request, method? : method) => {
  const [responseAPI, setResponseAPI] = React.useState<ResponseInterface>({onLoading: false, onFailure: false, onSuccess: false});

  const {dispatch, data} = React.useContext(DataContext);

  const setURL = (baseUrl : string, id? : number): string => {
    let url = baseUrl;
    if (id && baseUrl.includes("{id}")) 
      url = baseUrl.replace("{id}", id.toString());
    else if (id) 
      url += `/${id}`;
    return url;
  };

  React.useEffect(() => {
    if (name && method) {
      const request: ServiceInterface | undefined = REQUESTS.find((req) => req.name === name && req.method === method);
      if (!request) 
        throw new Error("request doesn't exist");
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setReq = (req ? : RequestInterface) => {
    if (req?.name) 
      name = req.name;
    if (req?.method) 
      method = req.method;
    
    const request: ServiceInterface | undefined = REQUESTS.find((req) => req.name === name && req.method === method);
    if (!request) 
      throw new Error("request doesn't exist");
    
    setResponseAPI({
      ...responseAPI,
      onLoading: true,
      onSuccess: false,
      onFailure: false
    });

    let item = null
    if(req?.id && method ==="GET" && request.reducerPath) item = data[request.reducerPath].find((i : any) => i.id === req.id)
    if (request.reducerPath && data.isCall[request.reducerPath] && method === "GET" && !req?.id) {
      setResponseAPI({
        ...responseAPI,
        onSuccess: true,
        onFailure: false,
        onLoading: false,
        data: data[request.reducerPath]
      });
    } else if(request.reducerPath && method === "GET" && item?.isCall) {
      setResponseAPI({
        ...responseAPI,
        onSuccess: true,
        onFailure: false,
        onLoading: false,
        data: item
      });
    }
     else {
       console.log("req ID :", req?.id)
      const url = setURL(request.url, req?.id);

      let a = {
        ...data
      };

      setRequestAPI(request.method, url, req?.body, req?.params).then(({data}) => {
        if (request.method === "POST" && request.reducerPath && request.object) {
          const [l] = List.addItem(a[request.reducerPath], new request.object(data));
          const [list] = List.deleteItem(l, 0);
          dispatch({newData: list, reducerPath: request.reducerPath});
        }

        if (request.method === "PUT" && request.reducerPath && request.object) {
          const [list] = List.updateItem(a[request.reducerPath], new request.object(data));
          dispatch({newData: list, reducerPath: request.reducerPath});
        }

        if (request.method === "DELETE" && request.reducerPath) {
          if (!req?.id) 
            throw new Error("Id can't be null");
          const [list] = List.deleteItem(a[request.reducerPath], req.id);
          dispatch({newData: list, reducerPath: request.reducerPath});
        }

        if (request.method === "GET" && request.object && Array.isArray(data)) {
          let objects: Array<any> = [];
          data.forEach((obj : Object) => {
            objects.push(new request.object(obj));
          });

          if (request.reducerPath) 
            dispatch({newData: objects, reducerPath: request.reducerPath});

          setResponseAPI({
            ...responseAPI,
            onLoading: false,
            onSuccess: true,
            onFailure: false,
            data: objects
          });
        }

        if(request.object && request.method === "GET" && !Array.isArray(data)) {
          let obj = new request.object(data);

          if(request.reducerPath) {
            obj.isCall = true
            const [list] = List.updateItem(a[request.reducerPath], obj)
            dispatch({newData: list, reducerPath: request.reducerPath});
          }

          setResponseAPI({
            ...responseAPI,
            onLoading: false,
            onSuccess: true,
            onFailure: false,
            data: obj
          });
        }

        else if (request.object && Array.isArray(data)) {} else if (request.object) {
          let obj = new request.object(data);
          setResponseAPI({
            ...responseAPI,
            onLoading: false,
            onSuccess: true,
            onFailure: false,
            data: obj
          });
        } else 
          setResponseAPI({
            ...responseAPI,
            onLoading: false,
            onSuccess: true,
            onFailure: false,
            data
          });
        }
      ).catch((err) => {

        console.error(err?.response);
        let error = "Une erreur est survenue"
        if(err?.response?.data?.name) error = err.response.data.name
        console.log(error)
        setResponseAPI({
          ...responseAPI,
          onLoading: false,
          onSuccess: false,
          onFailure: true,
          error
        });
      });
    }
  };

  return [setReq, responseAPI] as const;
};

export const usePostFile = () => {
  const [responseAPI, setResponseAPI] = React.useState<ResponseInterface>({onLoading: false, onFailure: false, onSuccess: false});

  const setReq = (file : FormData) => {
    postFile(file).then((res) => {
      const file: FileInterface = res.data;

      setResponseAPI({
        ...responseAPI,
        onLoading: false,
        onFailure: false,
        onSuccess: true,
        data: new File(file)
      });
    });
  };

  return [setReq, responseAPI] as const;
};

export const usePutFile = () => {
  const [responseAPI, setResponseAPI] = React.useState<ResponseInterface>({onLoading: false, onFailure: false, onSuccess: false});

  const setReq = (file : FormData, id : number) => {
    setResponseAPI({
      ...responseAPI,
      onLoading: true,
      onFailure: false,
      onSuccess: false
    });
    putFile(file, id).then((res) => {
      const file: FileInterface = res.data;

      setResponseAPI({
        ...responseAPI,
        onLoading: false,
        onFailure: false,
        onSuccess: true,
        data: new File(file)
      });
    });
  };

  return [setReq, responseAPI] as const;
};

export const useDeleteFile = () => {
  const [responseAPI, setResponseAPI] = React.useState<ResponseInterface>({onLoading: false, onFailure: false, onSuccess: false});

  const setReq = (id : number) => {
    setResponseAPI({
      ...responseAPI,
      onLoading: true,
      onFailure: false,
      onSuccess: false
    });
    setRequestAPI("DELETE", `/api/files/${id}`, undefined, {}).then((res) => {
      const file: FileInterface = res.data;

      setResponseAPI({
        ...responseAPI,
        onLoading: false,
        onFailure: false,
        onSuccess: true,
      });
    });
  };

  return [setReq, responseAPI] as const;
}

export default useAPI;
