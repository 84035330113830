import * as React from 'react';
import File from '../../objects/File';
import FormInterface from '../../objects/interfaces/FormInterface';
import Field from '../Field/Field';
import UploadFile from '../UploadFile/UploadFile';
import "./Form.css"
import request from '../../objects/types/request';
import useAPI from '../../hooks/useAPI';
import method from '../../objects/types/method';
import { useContext,  useEffect } from 'react';
import PageContext from '../../hooks/contexts/PageContext';
import List from '../../objects/List';
import { useMemo } from 'react';
import Select from '../Select/Select';
import CustomFieldInterface from '../../objects/interfaces/CustomFieldInterface';
import { toast } from 'react-toastify';
import SelectRoles from '../SelectLabels/SelectRoles';
import role from '../../objects/types/role';
import Checkbox from '../Checkbox/Checkbox';
import SelectTargetPerson from '../SelectTargetPerson/SelectTargetPerson';


export interface IModalDelete {
    onClickDelete ? : () => any,
    onCloseModal ? : () => any,
    openModal ? : boolean
}

export interface FormProps {
    form : Array<FormInterface>
    object : any
    onClose ? : () => any
    onValidate ? : (obj ? : any) => any
    request ? : request
    item ? : any
    type ? : method
    getItem ? : boolean
    btnDelete ? : boolean
    btnEdit ? : boolean
    customButtons ? : Array<JSX.Element>
    customFields? : Array<CustomFieldInterface>
    customPage ? : (form : Array<FormCustomInterface>, modalDelete  ? : IModalDelete ) => any
    directionButtons ? : "column" | "row"
    data ? : any
    showPopUpDelete ? : boolean
}
 
const Form: React.FunctionComponent<FormProps> = ({form, object, onClose=() => {}, type="POST", request="CUSTOMERS", item, getItem=false, btnDelete=true, btnEdit=true, customButtons=[], customFields=[], customPage, directionButtons="row", data, onValidate, showPopUpDelete}) => {

    const o = useMemo(() => (type === "PUT") ? item : (!data ? new object({}) : new object(data)), [item, type, object])

    const [obj, setObj] = React.useState<any>(o)
    const [oldObj, setOldObj] = React.useState<any>()

    const [openModal, setOpenModal] = React.useState<boolean>(false)


    const [oldList, setOldList] = React.useState<Array<any>>([])

    const {list , setList, onFailure : onFail} = useContext(PageContext)

    const [requestGetAPI, responseGetAPI] = useAPI(request, "GET")
    React.useEffect(() => {
        const {onSuccess , data } = responseGetAPI
        if(onSuccess) setObj(data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseGetAPI])

    const [requestPostAPI, responsePostAPI] = useAPI(request , type)
    React.useEffect(() => {
        const {onSuccess , data, onFailure, error } = responsePostAPI
        if(onSuccess) {
            const [listObj,] = List.addItem(oldList ,new object(data))
            setList(listObj)
            toast.success("Créer avec succès")
        }
        if(onFailure) {
            setList(oldList)
            onFail()
            if(object.ERROR(error)) toast.error(object.ERROR(error))
            else toast.error(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responsePostAPI])

    const [requestPutAPI, responsePutAPI] = useAPI(request , type)
    React.useEffect(() => {
        const {onSuccess ,  onFailure , error } = responsePutAPI
        if(onSuccess) {
            toast.success("Modifier avec succès")
        }
        if(onFailure) {
            setList(oldList)
            onFail(oldObj)
            if(object.ERROR(error)) toast.error(object.ERROR(error))
            else toast.error(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responsePutAPI])

    const [requestDeleteAPI, responseDeleteAPI] = useAPI(request, "DELETE")
    useEffect(() => {
        const {onSuccess, onFailure, error} = responseDeleteAPI

        if(onSuccess) {
            toast.success("Supprimer avec succès")

        }
        if(onFailure) {
            console.log(onFailure)
            setList(oldList)
            onFail(oldObj)
            if(object.ERROR(error)) toast.error(object.ERROR(error))
            else toast.error(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseDeleteAPI])

    useEffect(() => {
        setObj(o)
        if(getItem && o.id) requestGetAPI({id : o.id})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [o])

    const onChangeFile = (value : string, file ? : File) => {
        obj[value] = file
    }

    const onChangeText = (text : string, value : string) => {
        let o = new object({...obj})
        o[value] = text
        setObj(o)
    }

    const onChangeSelect = (name :string, value : any) => {
        let o = new object({...obj})
        o[name] = value
        setObj(o)
    }


    const deleteItem = () => {
        const [listObj, oldListObj] = List.deleteItem(list, obj.id)
        setList(listObj)
        setOldList(oldListObj)
        setOldObj(obj)

        if(!onValidate) onClose()
        else onValidate()

        requestDeleteAPI({id : obj.id})
    }

    const validateForm = () => {
        if(type === "POST") {
            if(!obj.BODY) throw new Error("The Body doesn't exist in this object")
            const [listObj, oldListObj] = List.addItem(list, obj)
            setList(listObj)
            setOldList(oldListObj)

            if(!onValidate) onClose()
            else onValidate(obj)

            requestPostAPI({body : obj.BODY})
        }
        if(type === "PUT") {
            if(!obj.BODY) throw new Error("The Body doesn't exist in this object")

            setOldObj(obj)

            const [listObj, oldListObj] = List.updateItem(list, obj)
            setList(listObj)
            setOldList(oldListObj)
            setOldObj(obj)

            if(!onValidate) onClose()
            else onValidate(obj)

            requestPutAPI({id : obj.id , body : obj.BODY})

        }
    }

    if(!customPage){
    return (  
        <div className="component-form">
            {form.map(field =>{

                if(field.config?.isVisible) {
                    const { name , equalTo, differentTo} = field.config.isVisible
                    if(equalTo !== undefined && obj[name] !== equalTo) return <></>
                    if(differentTo !== undefined && obj[name] === differentTo) return <></>
                }

                if(field.type ==="custom") {
                    let customField = customFields.find(f => f.name === field.value)
                    if(!customField) throw new Error("Custom Field doesn't exist")
                    return customField.renderItem(obj[field.value])
                }


                
                return(
            <div className="container-item">
                
                {field.type === "select" ? <Select key={`select-${field.value}`} value={obj[field.value] ?? ""}  wording={field.wording} config={field.config} onSelect={(value) => onChangeSelect(field.value, value)} ></Select>
                :  field.type === "readonly" ? <ReadOnly key={`readonly-${field.value}`} wording={field.wording} value={obj[field.value]}/>
                : field.type === "file" ? <UploadFile key={`upload-${field.value}`} fileUploaded={obj[field.value]}  wording={field.wording} onUploadedFile={(file?  :File) => onChangeFile(field.value, file)}/>
                : field.type === "select_role" ? <SelectRoles value={obj[field.value]} onChange={(role : Array<role>) => onChangeSelect(field.value, role)}/>
                : field.type === "boolean" ? <Checkbox value={obj[field.value]} onClick={() => setObj(new object({...obj, [field.value] : !obj[field.value]}))} wording={field.wording}/>
                : <Field key={`field-${field.value}`} type={field.type} value={obj[field.value] ?? ""} onChange={(text : string) => onChangeText(text, field.value)} wording={field.wording}/>}
                </div>)})}
            <div className="buttons" style={{flexDirection: directionButtons}}>
            <div className="btn bg-dark" onClick={onClose}>Fermer</div>
            {btnEdit && <div className="btn bg-black" onClick={validateForm}>Valider</div>}
            {(type === "PUT" && btnDelete) && <div className="btn bg-danger" onClick={deleteItem}>Supprimer</div>}
            {customButtons.map(btn => 
                btn
            )}
            </div>
        </div>
    );}
    else {
        let formCustom : Array<FormCustomInterface>  = []
        form.forEach(field =>{
    
            if(!field.position)  formCustom.push({
                component : <></>,
                position : ""
            })

            else if(field.config?.isVisible) {
                const { name , equalTo, differentTo} = field.config.isVisible
                if((equalTo !== undefined && obj[name] !== equalTo) || (differentTo !== undefined && obj[name] === differentTo)) 
                    formCustom.push({
                        component : <></>,
                        position : ""
                    })
            }

            else if(field.type ==="custom") {
                let customField = customFields.find(f => f.name === field.value)
                if(!customField) throw new Error("Custom Field doesn't exist")
                formCustom.push({
                    component : customField.renderItem(obj[field.value]),
                    position : field.position
                })
            }

            else {
                if(field.type === "select_target") formCustom.push({
                    component : <SelectTargetPerson value={obj[field.value] ?? []} onChange={(value) => onChangeSelect(field.value, value)}/>,
                    position : field.position
                })
                else if(field.type === "select") formCustom.push({
                    component : <Select className="custom-form-field" key={`select-${field.value}`} value={obj[field.value] ?? ""}  wording={field.wording} config={field.config} onSelect={(value) => onChangeSelect(field.value, value)} />,
                    position : field.position
                })
                else if(field.type === "readonly") formCustom.push({
                    component : <ReadOnly key={`readonly-${field.value}`} wording={field.wording} value={obj[field.value]}/>,
                    position : field.position
                })
                else if(field.type === "file") formCustom.push({
                    component : <UploadFile size={150} id={`upload-${field.value}`} key={`upload-${field.value}`} fileUploaded={obj[field.value]}  wording={field.wording} onUploadedFile={(file ? :File) => onChangeFile(field.value, file)}/>,
                    position : field.position
                })
                else {
                    formCustom.push({
                        component : <Field className="custom-form-field" key={`field-${field.value}`} type={field.type} value={obj[field.value] ?? ""} onChange={(text : string) => onChangeText(text, field.value)} wording={field.wording}/>,
                        position : field.position
                    })
                }
            }
        })
        formCustom.push({
            component : (
                <div className="buttons" style={{flexDirection : directionButtons}}>
                    <div className="btn bg-dark" onClick={onClose}>Fermer</div>
                    {btnEdit && <div className="btn bg-black" onClick={validateForm}>Valider</div>}
                    {(type === "PUT" && btnDelete) && (showPopUpDelete ?  <div className="btn bg-danger" onClick={() => setOpenModal(true)}>Supprimer</div> : <div className="btn bg-danger" onClick={deleteItem}>Supprimer</div>)}
                    {customButtons.map(btn => 
                        btn
                    )}
                </div>
            ),
            position : "buttons"
        })


        if(showPopUpDelete) return customPage(formCustom, {openModal, onCloseModal :() => setOpenModal(false), onClickDelete : deleteItem  })
        else return customPage(formCustom)
    }
}

export const ReadOnly = ({wording = "", value= "" }) => {
    return(
        <div className="read-only-container">
            <label  style={{position:"absolute", top:-10, left:25, fontSize :13}}>{wording}</label>
            <label>{value}</label>
        </div>
    )
}
export default Form;

export interface FormCustomInterface {
    component : JSX.Element,
    position : string
}
