import * as React from 'react';


const useCache = (name : string) => {
    const getCache = async  () => {
        return await localStorage.getItem(name)
    }

    const setCache = async (value : string) => {
        await localStorage.setItem(name, value)
        return true
    }
    return [getCache, setCache]
}

export default useCache;
