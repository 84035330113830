import reducerPath from "../../objects/types/reducerPath";


export interface DataReducerInterface {
        newData : any
        reducerPath : reducerPath

}

const DataReducer = (state : any, dataReducer : DataReducerInterface, ) => {


    const {newData, reducerPath} = dataReducer

    return {...state, [reducerPath] : newData , isCall : {
        ...state.isCall, [reducerPath] : true
    }}
}

export default DataReducer
