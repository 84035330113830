import File from './File';
import FileInterface from './interfaces/FileInterface';


class Media {
    id : number
    photo : File
    audio : File

    constructor(media ? : IMedia) {
        this.id = media?.id ?? 0;
        this.photo = new File(media?.photo)
        this.audio = new File(media?.audio);
    }
}

export interface IMedia {
    id : number
    photo : FileInterface
    audio : FileInterface
}

export default Media
