import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import useAPI from '../../hooks/useAPI';
import OptionInterface from '../../objects/interfaces/OptionInterface';
import "./Select.css"

export interface SelectProps {
    wording : string
    options ? : Array<OptionInterface>
    config ? : any
    onSelect : (value : any) => any
    value : any
    className ?: string
}
 
const Select: React.FunctionComponent<SelectProps> = ({wording, options=[], config={}, onSelect, value, className}) => {

    const [listOptions, setListOptions] = useState<Array<OptionInterface>>(options)


    const [requestAPI , responseAPI] = useAPI()
    useEffect(() => {
        const {onSuccess, data} = responseAPI
        if(onSuccess) {
            let list : Array<OptionInterface> = []
            data.forEach((item : any) => {
                let option : OptionInterface = {
                    name : item[config.api.modelOption.name],
                    value : item[config.api.modelOption.value]
                }
                list.push(option)
            });
            setListOptions(list)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseAPI])

    React.useEffect(() => {
        if(config?.api) {
            requestAPI(config.api)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    if(value && config?.api) value = value[config.api.modelOption.value]


    const onSelected = ({target} : any) => {
        const {value} = target
        let selected
        if(config?.api && value !== "") selected = responseAPI.data.find((item: any) => item[config.api.modelOption.value].toString() === value)
        else selected = value
        onSelect(selected)
    }


    return ( 
        <div className={"select-container"}>
            <label style={{position:"absolute", top:-10, left:25, fontSize :13}}>{wording}</label>
            <select value={value} onChange={onSelected} className="input">
                <option  value={""} ></option>
                {listOptions.map(opt => 
                    <option key={opt.value} value={opt.value}>{opt.name}</option>
                )}
            </select>
        </div>
     );
}
 
export default Select;
