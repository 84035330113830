import * as React from 'react';
import TabInterface from '../../objects/interfaces/TabInterface';
import "./TabsBar.css"


export interface TabsBarProps {
    tabs : Array<TabInterface>
    selectedTab : string
}
 
const TabsBar: React.FunctionComponent<TabsBarProps> = ({tabs, selectedTab}) => {
    return (
        <div className="tabsbar">
            {tabs.map((tab, id) => 
                selectedTab === tab.id ? <div key={id} className="tab-selected" onClick={tab.onClick}>{tab.title}</div> : <div key={id} className="tab" onClick={tab.onClick}>{tab.title}</div>
            )}
        </div>
     );
}
 
export default TabsBar;
