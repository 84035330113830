import * as React from 'react';
import type from '../../objects/types/type';
import "./Field.css"

export interface FieldProps {
    value : string
    onChange : (text : string) => any
    error ? : string
    setError ? : () => {}
    wording : string
    type ? : type
    className ? : string
    style ? : React.StyleHTMLAttributes<any>
}
 
const Field: React.FunctionComponent<FieldProps> = ({value, onChange, wording, type="text"}) => {

    const onHandleChange = (event : any) => {
        const {currentTarget} = event
        const { value } = currentTarget
        onChange(value)
    }
    return (
        <div className={ "field-container"}>
            <label style={{position:"absolute", top:-10, left:25, fontSize :13}}>{wording}</label>
            {type !== "textarea" ? <input min={0}  type={type} className={"input"} value={value} onChange={onHandleChange}></input> :
                <textarea rows={5} className={"input"} value={value} onChange={onHandleChange}></textarea>
            }
        </div>
     );
}
 
export default Field;
