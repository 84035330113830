import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import DataContext from '../../hooks/contexts/DataContext';
import useCache from '../../hooks/useCache';
import logo from '../../images/logobyciavatti.png';
import "./Navbar.css"
import useData from '../../hooks/useData';


const Navbar: React.FunctionComponent<NavbarProps> = ({ onClickBurgerMenu = () => null, namePage = "Catégorie", history }) => {
    const [, setToken] = useCache("token")
    const { dispatch } = React.useContext(DataContext)

    const [currentAdmin] = useData("currentAdmin")
    const logOut = () => {
        setToken("")
        dispatch({ reducerPath: "token", newData: "" })
    }

    return (
        <div className="navbar bg-white w-100">
            <div className="navbar-content">
                <FontAwesomeIcon icon={faBars} className="navbar-menu-burger navbar-title" onClick={onClickBurgerMenu} />
                <div className="navbar-title" onClick={() => history.push("/")}>
                <img src={logo} className="logo-title" alt="logo" />
                </div>
                <div className="navbar-title" onClick={onClickBurgerMenu}>{namePage}</div>
            </div>
            <div className="navbar-actions">
                <label className="btn-action">
                    Bonjour {`${currentAdmin?.firstName ?? ""} ${currentAdmin?.lastName ?? ""}`}
                </label>
                <FontAwesomeIcon icon={faSignOutAlt} className="navbar-sign-out btn-action" onClick={logOut} />
            </div>
        </div>
    );
}

export default Navbar;

export interface NavbarProps {
    onClickBurgerMenu: () => void
    namePage?: string
    history ? : any
}
