import * as React from 'react';
import reducerPath from '../objects/types/reducerPath';
import DataContext from './contexts/DataContext';

const useData = (reducerPath  : reducerPath) => {

    const [item, setItem] = React.useState<any>()

    const [redPath, setReducerPath] = React.useState<reducerPath>(reducerPath)


    const {data, dispatch } = React.useContext(DataContext)

    React.useEffect(() => {
        let isCancelled = false

        if(redPath && data[redPath] && !isCancelled) {
            setItem(data[redPath])
        }

        return () =>{
            isCancelled = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data[redPath], redPath])





    const setData = (newData : any) : void  => {
        dispatch({newData, reducerPath : redPath })
    }


    return [item, setData, setReducerPath] as const
}

export default useData
