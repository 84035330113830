import * as React from 'react';
import Page from '../../../components/Page/Page';
import PageContext from '../../../hooks/contexts/PageContext';
import useAPI from '../../../hooks/useAPI';
import "./Invoices.css"
import Invoice from '../../../objects/Invoice';
import Table from '../../../components/Table/Table';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect } from 'react';

export interface InvoicesProps extends RouteComponentProps {

}

const Invoices: React.FunctionComponent<InvoicesProps> = ({history}) => {

    const [invoices, setInvoices] = React.useState<Array<Invoice>>([])
    const [getInvoices, responseGetInvoices] = useAPI("INVOICES", "GET")
    useEffect(() => {
        const {onSuccess, data} = responseGetInvoices
        if(onSuccess) {
            console.log()
            setInvoices(data)
        }
    }, [responseGetInvoices])
    useEffect(() => {
        getInvoices()
    }, [])

    const onFailure = () => {

    }



    return ( 
        <Page history={history} name="Factures">
            <div className="container-invoices">
            <PageContext.Provider value={{list : invoices, setList : setInvoices, onFailure}}>
                <div className="container-invoices">
                    <Table  isClickable={false} filters={Invoice.FILTERS_FOR_INVOICES} isExportable={true} onClickItem={() => {}} columns={Invoice.COLUMNS} data={invoices}/>
                </div>
            </PageContext.Provider>
            </div>
        </Page>
     );
}
 
export default Invoices;
