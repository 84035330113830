import NotificationInterface from './interfaces/NotificationInterface';


class Notification {
    id : number
    title : string
    content : string
    recipients : number
    programmedAt : string | null
    _programmedAtDate : string
    _programmedAtTime : string

    constructor (notification ? : NotificationInterface) {
        this.id = notification?.id ?? 0
        this.title = notification?.title ?? ""
        this.content = notification?.content ?? ""
        this.recipients = notification?.recipients ?? 0
        this.programmedAt = notification?.programmedAt ?? null
        this._programmedAtDate = notification?._programmedAtDate ?? ""
        this._programmedAtTime = notification?._programmedAtTime ?? ""
    }
}

export default Notification
