import * as React from 'react';
import Page from '../../../components/Page/Page';
import Table from '../../../components/Table/Table';
import "./Orders.css"
import Order from '../../../objects/Order';
import TabsBar from '../../../components/TabsBar/TabsBar';
import useAPI from '../../../hooks/useAPI';
import Form, { FormCustomInterface } from '../../../components/Form/Form';
import CustomFieldInterface from '../../../objects/interfaces/CustomFieldInterface';
import ProductOrder from '../../../objects/ProductOrder';
import Product from '../../../objects/Product';
import useData from '../../../hooks/useData';
import List from '../../../objects/List';
import { RouteComponentProps } from 'react-router-dom';
import io, {Socket} from "socket.io-client"
import orderData from "../../../orderData.json"
import IDataTagAddedEvent from '../../../objects/interfaces/IDataTagAddedEvent';
import URL_SOCKET from '../../../constants/URL_SOCKET';
import NfcTag from '../../../objects/NfcTag';
import URL_API from '../../../constants/URL_API';
import User from '../../../objects/User';

export interface OrdersProps extends RouteComponentProps<any>{
}

const socketUrl : string = URL_SOCKET ?? ""

const Orders: React.FunctionComponent<OrdersProps> = ({history, match}) => {

    const [socket, setSocket] = React.useState<Socket>();

    const [currentAdmin, setCurrentAdmin] = useData("currentAdmin")

    const id = match?.params?.id

    const [currentPage, setCurrentPage] = React.useState<string>("order-no-processed")

    const [ordersProcessed, setOrdersProcessed] = React.useState<Array<Order>>([])
    const [ordersNoProcessed, setOrdersNoProcessed] = React.useState<Array<Order>>([])
    React.useEffect(() => {
        console.log("ORDER NO PROCESSED =>")
        console.log(ordersNoProcessed)
    }, [ordersNoProcessed]);


    const [tagsAdded, setTagsAdded] = React.useState<Array<IDataTagAddedEvent>>([]);
    const [tagsNfc, setTagsNfc] = React.useState<Array<NfcTag>>([]);
    const [orderSelected, setOrderSelected] = React.useState<Order>()
    React.useEffect(() => {
        if(orderSelected) {
            let allTagsNfc : Array<NfcTag>= [...orderSelected?.nfcTags ?? []]
            console.log("allTagsNfc")
            allTagsNfc = tagsAdded?.reduce((acc : Array<NfcTag> , curr : IDataTagAddedEvent) => {
                if(curr.orderId === orderSelected?.id.toString())  acc.push(new NfcTag({ product : { id : curr.productId}, }))
                return acc
            }, allTagsNfc) ?? allTagsNfc
            console.log(allTagsNfc)
            setTagsNfc(allTagsNfc)
        }
    }, [orderSelected?.nfcTags]);

    const [getCurrentAdmin, responseGetCurrentAdmin] = useAPI("CURRENT_ADMIN", "GET")
    React.useEffect(() => {
        const { onSuccess, data } = responseGetCurrentAdmin
            if(onSuccess) {
                setCurrentAdmin(new User(data))
        }
    }, [responseGetCurrentAdmin]);

    React.useEffect(() => {
    }, []);



    const [getOrdersNoProcessed, responseGetOrdersNoProcessed] = useAPI("ORDERS_NO_PROCESSED", "GET")
    React.useEffect(() => {
        const { onSuccess,  data, } = responseGetOrdersNoProcessed
        console.log("refresh DATA ORDER NO PROCESSED")
        console.log(data)
        if(onSuccess) setOrdersNoProcessed(data)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseGetOrdersNoProcessed])

    const [getOrdersProcessed, responseGetOrdersProcessed] = useAPI("ORDERS_PROCESSED", "GET")
    React.useEffect(() => {
        const { onSuccess,  data } = responseGetOrdersProcessed

        if(onSuccess) setOrdersProcessed(data)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseGetOrdersProcessed])

    const [getOrder, responseGetOrder] = useAPI("ORDERS", "GET")
    React.useEffect(() => {
        const {onSuccess , data } = responseGetOrder
            console.log(responseGetOrder)
            if(onSuccess) {
            setOrderSelected(data)
        }
    }, [responseGetOrder]);

    React.useEffect(() => {
        getOrdersNoProcessed({params : {isProcessed : false}})
        getOrdersProcessed({params : {isProcessed : true}})
        getCurrentAdmin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(() => {
        if(tagsAdded){
        let allTagsNfc : Array<NfcTag>= [...orderSelected?.nfcTags ?? []]
        allTagsNfc = tagsAdded?.reduce((acc : Array<NfcTag> , curr : IDataTagAddedEvent) => {
            if(curr.orderId === orderSelected?.id.toString())  acc.push(new NfcTag({ product : { id : curr.productId}, }))
            return acc
        }, allTagsNfc) ?? allTagsNfc
        setTagsNfc(allTagsNfc)
    }
    }, [tagsAdded]);

    React.useEffect(() => {
        if(id) getOrder({id})
        initSocket()
    }, [id]);

    const onTagAdded = (data : IDataTagAddedEvent) => {
  //      let order = ordersProcessed.find((o : Order) => o.id.toString() === d.orderId)
        console.log(data)
        let tAdded = [...tagsAdded]
        tAdded.push(data)
        setTagsAdded(tAdded)
    }

    const initSocket = () => {
        let socket = io(socketUrl)
        setSocket(socket)
        socket.on('connect', () => console.log("Connecté"))
        socket.on('product_scanned', onTagAdded)
        socket.on('order_payed', (data) => {
                if(data.order.hasProduct) {
                    getOrdersNoProcessed({params : {isProcessed : false}})
                }
        })
        //let d =
    }
    return ( 
        <Page history={history} name="Commandes">
            <>
            <div className="container-orders">

                <TabsBar selectedTab={currentPage} tabs={[
                    {title : "Commandes à Traiter", onClick : () => setCurrentPage("order-no-processed"), id : "order-no-processed"},
                    {title : "Commandes Traitées", onClick : () => setCurrentPage("order-processed"), id : "order-processed"}
                ]}/>
                <Table onClickItem={(order) =>{
                    setOrderSelected(order)
                    getOrder({id : order.id})
                }} customCsv={Order.EXPORT_CSV} columns={Order.COLUMNS} data={currentPage === "order-no-processed" ? ordersNoProcessed : ordersProcessed} isExportable={true} filters={Order.FILTERS_FOR_ORDERS}/>

            </div>
            <ModalOrder tags={tagsNfc} show={orderSelected ? true : false} order={orderSelected} onClose={() => setOrderSelected(undefined)} />
            </>
        </Page>
     );
}

export interface ModalOrderProps {
    order ? : Order
    show : boolean
    onClose : () => any
    tags ? : Array<NfcTag>
}

const ModalOrder: React.FunctionComponent<ModalOrderProps> = ({order, show, onClose, tags}) => {

    const [ordersNoProcessed, setOrderNoProcessed] = useData("ordersNoProcessed")
    const [oldOrdersNoProcessed, setOldOrdersNoProcessed] = React.useState<Array<Order>>()
    const [ordersProcessed, setOrderProcessed] = useData("ordersProcessed")
    const [oldOrdersProcessed, setOldOrdersProcessed] = React.useState<Array<Order>>()
    const [idInvoice, setIdInvoice] = React.useState<number>()

    const [validateOrder, responseValidateOrder] = useAPI("VALIDATE_ORDER", "PUT")
    React.useEffect(() => {
        const { onSuccess, onFailure} = responseValidateOrder
        if(onFailure) {
            setOrderNoProcessed(oldOrdersNoProcessed)
            setOldOrdersProcessed(oldOrdersProcessed)
        }

        if(onSuccess) {
            if(idInvoice) window.open(`${URL_API}/api/invoice/pdf/${idInvoice}`, "_blank")
            window.open(`${URL_API}/api/invoice/pdf/${idInvoice}`, "_blank")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseValidateOrder])

    const [cancelOrder, responseCancelOrder] = useAPI("CANCEL_ORDER", "PUT")
    React.useEffect(() => {
        const { onFailure} = responseCancelOrder
        if(onFailure) {
            setOrderNoProcessed(oldOrdersNoProcessed)
            setOldOrdersProcessed(oldOrdersProcessed)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseCancelOrder])

    const processOrder = (type :"validate" | "cancel") => {
        if(type === "validate") {
            validateOrder({id : order?.id})
            window.open(`${URL_API}/api/delivery_note/pdf/${order?.id}`, "_blank")
            setIdInvoice(order?.invoice?.id)
        }
        if(type === "cancel") cancelOrder({id : order?.id})
        const [newOrdersNoProcessed, oldListNoProcessed] = List.deleteItem(ordersNoProcessed, order?.id  ?? 0)
        console.log(newOrdersNoProcessed)
        setOrderNoProcessed(newOrdersNoProcessed)
        setOldOrdersNoProcessed(oldListNoProcessed)
       const [newOrdersProcessed, oldListProcessed] = List.addItem(ordersProcessed, order)
        console.log(newOrdersProcessed)
        setOrderProcessed(newOrdersProcessed)
       setOldOrdersNoProcessed(oldListProcessed)
       onClose()
    }

    React.useEffect(() => {

    }, [tags]);

    const customButtons : Array<JSX.Element>= !(order?.isProcessed) ? [
        <div className="btn bg-black" onClick={() => processOrder("validate")}>VALIDER ET IMPRIMER LE BL</div>,
        <div className="btn bg-danger" onClick={() => processOrder("cancel")}>ANNULER LA COMMANDE</div>,

    ] : [<div className="btn bg-info" onClick={() => window.open(`${URL_API}/api/invoice/pdf/${order?.invoice?.id}`, "_blank")}>Réediter la facture</div>,]

    const customFields : Array<CustomFieldInterface> = [
        {
            name : "productsTable",
            renderItem : (products : Array<ProductOrder>) => <ProductTable tags={tags} products={products} isProcessed={order?.isProcessed }/>
        }
    ]


    return ( 
        <div className={show ? "modal-order" : "container-modal-hide"}>
        <div className="order-container">
            <Form
                item={order ?? {} }
                type="PUT"
                request="ORDERS"
                form={Order.FORM}
                object={Order}
                onClose={onClose}
                btnEdit={false}
                btnDelete={false}
                getItem={true}
                customButtons={customButtons}
                customFields={customFields}
                customPage={(form) => <ModelOrderForm form={form}/>}
                directionButtons="column"
                />
        </div> 

    </div>
     );
}

export interface ModelOrderFormProps {
    form : Array<FormCustomInterface>
}

const ModelOrderForm : React.FunctionComponent<ModelOrderFormProps> = ({form}) => {
    return (
        <div className="form-order">
            <div className="order-grid-1 bg-white">
            {form.map(field =>
                    field.position === "order-grid-1" &&
                        field.component
                )}
            </div>
            <div className="order-grid-2 bg-white">
                <h3 >Client</h3>
            {form.map(field =>
                    field.position === "order-grid-2" &&
                        field.component
                )}
            </div>
            <div className="order-grid-3 bg-white">
                <h3 >Adresse de Facturation</h3>
            {form.map(field =>
                    field.position === "order-grid-3" &&
                        field.component
                )}
            </div>
            <div className="order-grid-4 bg-white">
                <h3 >Adresse de Livraison</h3>
            {form.map(field =>
                    field.position === "order-grid-4" &&
                        field.component
                )}
            </div>
            <div className="order-grid-5 bg-white">
                <h3 >Récapitulatif de la commande</h3>
            {form.map(field =>
                    field.position === "order-grid-5" &&
                        field.component
                )}
            </div>
            <div className="order-grid-6 bg-white">
            {form.map(field =>
                    field.position === "buttons" &&
                        field.component
                )}
            </div>
        </div>
    )
}

export interface ProductTableProps {
    products  : Array<ProductOrder>
    isProcessed ? : boolean
    tags ? : Array<NfcTag>
}


const ProductTable : React.FunctionComponent<ProductTableProps> = ({products, isProcessed, tags}) => {

    const [productsWithTag, setProductsWithTag] = React.useState<Array<ProductOrder>>();

    React.useEffect(() => {
        if(products) {
            let productsOrder : Array<ProductOrder> = []
            products?.forEach(product => {
                let tagsProducts = tags?.filter(t => t.product?.id === product.product?.id) ?? []
                product._productsTagged = 0
                tagsProducts.forEach(tag => {
                    if(product._productsTagged) product._productsTagged += 1
                    else  product._productsTagged = 1
                    console.log(product)
                })
                productsOrder.push(product)
            });
            setProductsWithTag(productsOrder)
        }
    }, [tags, products]);

    const linkProductWithNFC = (product ? : Product) => {
        if(!product) throw new Error("Product can't be null")
        console.log(product)
    }

    const customButtons = (product : ProductOrder) => {
        let btns = []
        let productTagged = product._productsTagged ?? 0
        for(let i=0; product.amount > i; i++){
            if(productTagged >= 1) btns.push(<div key={`btn-nfc-${i}`} className="btn bg-success">Relié</div>)
            else btns.push(<div key={`btn-nfc-${i}`} className="btn bg-danger">Non relié</div>)
            productTagged--
        }
        return btns.map(btn => btn)
    }
    return(
        <Table columns={ProductOrder.COLUMNS} data={productsWithTag ?? []} customButtons={customButtons} hasBtnAction={!isProcessed}/>
    )
}
export default Orders;
